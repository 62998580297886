// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accounting-bookkeeping-js": () => import("./../../src/pages/accounting-bookkeeping.js" /* webpackChunkName: "component---src-pages-accounting-bookkeeping-js" */),
  "component---src-pages-client-management-js": () => import("./../../src/pages/client-management.js" /* webpackChunkName: "component---src-pages-client-management-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-talk-js": () => import("./../../src/pages/lets-talk.js" /* webpackChunkName: "component---src-pages-lets-talk-js" */),
  "component---src-pages-other-services-js": () => import("./../../src/pages/other-services.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-payroll-js": () => import("./../../src/pages/payroll.js" /* webpackChunkName: "component---src-pages-payroll-js" */),
  "component---src-pages-transfer-pricing-js": () => import("./../../src/pages/transfer-pricing.js" /* webpackChunkName: "component---src-pages-transfer-pricing-js" */),
  "component---src-templates-blog-js": () => import("./../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-career-js": () => import("./../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-careers-js": () => import("./../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-category-js": () => import("./../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

